import { useEffect, useRef, useCallback, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";

const SessionChecker = () => {
  const navigate = useNavigate();
  const intervalRef = useRef(null);
  const isCheckingRef = useRef(false);
  const [lastChecked, setLastChecked] = useState(Date.now());

  // Use useCallback to memoize the handler
  const handleSessionExpiration = useCallback(() => {
    console.log("Session expired, logging out and redirecting");
    Auth.signOut()
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error during sign out:", error);
        navigate("/");
      });
  }, [navigate]);

  // Debounce the session check to prevent rapid consecutive checks
  const checkSession = useCallback(
    debounce(async () => {
      if (isCheckingRef.current) {
        console.log("Session check already in progress");
        return;
      }

      try {
        console.log("Checking session status");
        isCheckingRef.current = true;

        const session = await Auth.currentSession();

        // Check if session exists and is valid
        if (!session || !session.isValid()) {
          console.warn("Session is invalid or missing");
          handleSessionExpiration();
          return;
        }

        // Additional check for token expiration
        const expirationTime = session.getAccessToken().getExpiration() * 1000;
        const currentTime = Date.now();

        console.log("Token expiration time:", new Date(expirationTime));
        console.log("Current time:", new Date(currentTime));

        if (currentTime >= expirationTime) {
          console.warn("Access token has expired");
          handleSessionExpiration();
        } else {
          console.log("Session is valid");
          setLastChecked(Date.now());
        }
      } catch (error) {
        console.error("Session check error:", error);
        // Only navigate away if it's a real error, not just a "no current user" message
        if (
          error.message !== "No current user" &&
          error.message !== "No current session"
        ) {
          handleSessionExpiration();
        }
      } finally {
        isCheckingRef.current = false;
      }
    }, 1000),
    [handleSessionExpiration]
  );

  useEffect(() => {
    // Set up Hub listener for auth events
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log("Auth event received:", event, data);

      switch (event) {
        case "tokenRefresh_failure":
        case "signOut":
        case "signIn_failure":
          console.warn(`Auth event triggered: ${event}`);
          handleSessionExpiration();
          break;
        case "tokenRefresh":
          console.log("Token refreshed successfully");
          setLastChecked(Date.now());
          break;
        default:
          break;
      }
    });

    // Initial session check
    checkSession();

    // Set up interval with ref
    intervalRef.current = setInterval(() => {
      console.log(
        "Running interval check, isCheckingRef:",
        isCheckingRef.current
      );
      if (!isCheckingRef.current) {
        checkSession();
      }
    }, 60000); // Check every minute

    // Add window focus event listener to check session when tab is focused
    const handleFocus = () => {
      console.log("Window focused - checking session");
      checkSession();
    };

    window.addEventListener("focus", handleFocus);

    // Cleanup function
    return () => {
      console.log("Cleaning up SessionChecker");
      unsubscribe();
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      window.removeEventListener("focus", handleFocus);
      checkSession.cancel(); // Cancel any pending debounced calls
    };
  }, [checkSession, handleSessionExpiration]);

  // This component doesn't render anything visible but we can track its state
  return (
    <div
      style={{ display: "none" }}
      data-testid="session-checker"
      data-last-checked={lastChecked}
    >
      {/* Hidden component for testing */}
    </div>
  );
};

export default SessionChecker;
