import { createContext, useState, useMemo, useEffect } from "react";
import { createTheme } from "@mui/material/styles";

// Base color palette definitions
const baseColors = {
  grey: {
    10: "#FFFFFF",
    50: "#f5f5f5",
    100: "#e0e0e0",
    200: "#c2c2c2",
    300: "#a3a3a3",
    400: "#858585",
    500: "#666666",
    600: "#525252",
    700: "#3d3d3d",
    800: "#292929",
    900: "#141414",
  },
  primary: {
    50: "#e3f2fd",
    100: "#bbdefb",
    200: "#90caf9",
    300: "#64b5f6",
    400: "#42a5f5",
    500: "#2196f3",
    600: "#1e88e5",
    700: "#1976d2",
    800: "#1565c0",
    900: "#0d47a1",
  },
};

// Accent color palettes
const accentColors = {
  greenAccent: {
    100: "#dbf5ee",
    200: "#b7ebde",
    300: "#94e2cd",
    400: "#70d8bd",
    500: "#4cceac",
    600: "#3da58a",
    700: "#2e7c67",
    800: "#1e5245",
    900: "#0f2922",
  },
  redAccent: {
    100: "#f8dcdb",
    200: "#f1b9b7",
    300: "#e99592",
    400: "#e2726e",
    500: "#db4f4a",
    600: "#af3f3b",
    700: "#832f2c",
    800: "#58201e",
    900: "#2c100f",
  },
  blueAccent: {
    100: "#e1e2fe",
    200: "#c3c6fd",
    300: "#a4a9fc",
    400: "#868dfb",
    500: "#6870fa",
    600: "#535ac8",
    700: "#3e4396",
    800: "#2a2d64",
    900: "#151632",
  },
  cyanAccent: {
    100: "#B2EBF2",
    200: "#80DEEA",
    300: "#4DD0E1",
    400: "#26C6DA",
    500: "#00BCD4",
    600: "#00ACC1",
    700: "#0097A7",
    800: "#00838F",
    900: "#006064",
  },
  tealAccent: {
    100: "#B2DFDB",
    200: "#80DEEA",
    300: "#4DB6AC",
    400: "#26A69A",
    500: "#009688",
    600: "#00897B",
    700: "#00796B",
    800: "#00695C",
    900: "#004D40",
  },
  orangeAccent: {
    100: "#FFEBD3",
    200: "#FFD1A6",
    300: "#FFB579",
    400: "#FF9E4C",
    500: "#FF871F",
    600: "#FF6F00",
    700: "#E66600",
    800: "#CC5C00",
    900: "#B25300",
  },
  yellowAccent: {
    100: "#FFF9D3",
    200: "#FFF3A6",
    300: "#FFED79",
    400: "#FFE74C",
    500: "#FFE11F",
    600: "#FFDB00",
    700: "#E6C900",
    800: "#CCB300",
    900: "#B29D00",
  },
  pinkAccent: {
    100: "#F8BBD0",
    200: "#F48FB1",
    300: "#F06292",
    400: "#EC407A",
    500: "#E91E63",
    600: "#D81B60",
    700: "#C2185B",
    800: "#AD1457",
    900: "#880E4F",
  },
  purpleAccent: {
    100: "#E1BEE7",
    200: "#CE93D8",
    300: "#BA68C8",
    400: "#AB47BC",
    500: "#9C27B0",
    600: "#8E24AA",
    700: "#7B1FA2",
    800: "#6A1B9A",
    900: "#4A148C",
  },
  deepOrangeAccent: {
    100: "#FFCCBC",
    200: "#FFAB91",
    300: "#FF8A65",
    400: "#FF7043",
    500: "#FF5722",
    600: "#F4511E",
    700: "#E64A19",
    800: "#D84315",
    900: "#BF360C",
  },
  brownAccent: {
    100: "#D7CCC8",
    200: "#BCAAA4",
    300: "#A1887F",
    400: "#8D6E63",
    500: "#795548",
    600: "#6D4C41",
    700: "#5D4037",
    800: "#4E342E",
    900: "#3E2723",
  },
};

// Theme mode specific settings
const modeSettings = {
  dark: {
    iconColor: { 100: "#FFFFFF" },
    topbar: { background: "#020817" },
    menu: {
      background: "#020817",
      text: "#fff",
      open: { background: "transparent", text: "#1e88e5" },
      menu: { background: "#020817" },
      active: { background: "transparent", text: "#1e88e5" },
    },
    logo: "#1e88e5",
    secondary: "#191e3a",
    background: "#060818",
    foreground: "#e0e6ed",
    menuActive: "#25d5e4",
    menuText: "#e0e6ed",
    menuBackground: "#191e3a",
    tableBackground: "#0e1726",
  },
  light: {
    iconColor: { 100: "#6870fa" },
    topbar: { background: "#020817" },
    menu: {
      background: "#020817",
      text: "#fff",
      open: { background: "transparent", text: "#1e88e5" },
      menu: { background: "#020817" },
      active: { background: "transparent", text: "#1e88e5" },
    },
    logo: "#1e88e5",
    secondary: "#191e3a",
    background: "#f1f2f3",
    foreground: "#515365",
    menuActive: "#25d5e4",
    menuText: "#e0e6ed",
    menuBackground: "#191e3a",
    tableBackground: "#fff",
  },
};

// Typography configuration
const typography = {
  fontFamily: ["Nunito", "sans-serif"].join(","),
  fontSize: 12,
  h1: { fontSize: 40 },
  h2: { fontSize: 32 },
  h3: { fontSize: 24 },
  h4: { fontSize: 20 },
  h5: { fontSize: 18 },
  h6: { fontSize: 16 },
};

// Custom component style overrides
const componentOverrides = {
  MuiSwitch: {
    styleOverrides: {
      switchBase: {
        color: "#ccc",
      },
      colorPrimary: {
        "&.Mui-checked": {
          color: "#f2ff00",
        },
      },
      track: {
        opacity: 0.2,
        backgroundColor: "#fff",
        ".Mui-checked.Mui-checked + &": {
          opacity: 0.7,
          backgroundColor: "#fff",
        },
      },
    },
  },
};

// Generate complete tokens based on mode
export const tokens = (mode) => ({
  ...baseColors,
  ...accentColors,
  ...modeSettings[mode],
});

// Generate theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);

  return {
    palette: {
      mode,
      primary: { main: colors.primary[700] },
      secondary: { main: colors.secondary },
      cyan: { main: colors.cyanBright },
      success: { main: colors.greenAccent[700] },
      error: { main: colors.redAccent[500] },
      background: { default: colors.background },
    },
    typography: {
      ...typography,
      color: mode === "dark" ? colors.grey[100] : colors.grey[800],
    },
    components: componentOverrides,
  };
};

// Color mode context
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

// Custom hook for theme management
export const useMode = () => {
  const [mode, setMode] = useState(
    () => localStorage.getItem("themeMode") || "light"
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prev) => {
          const newMode = prev === "light" ? "dark" : "light";
          localStorage.setItem("themeMode", newMode);
          return newMode;
        });
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", mode);
  }, [mode]);

  return [theme, colorMode];
};
