import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Auth } from "aws-amplify";
import { getData } from "../utils/apis";
import { sortMenuItems } from "../utils/menu";
import { useNotificationHandling } from "../utils/notificationHandle";

const staticDefaultUserValue = {
  practiceId: "",
  email: "",
  firstName: "",
  lastName: "",
  securityGroupMenus: [],
};

export const UserContext = createContext(staticDefaultUserValue);

function UserProvider({ children }) {
  const [user, setUser] = useState(staticDefaultUserValue);
  const [menuItems, setMenuItems] = useState([]);
  const [menus, setMenus] = useState([]);
  const [securityGroupMenus, setSecurityGroupMenus] = useState([]);
  const [defaultUserData, setDefaultUserData] = useState(
    staticDefaultUserValue
  );
  const [loading, setLoading] = useState(true);
  const securities = useRef([]);
  const securityMapRef = useRef(null);
  const { handleErrorNotification } = useNotificationHandling();

  const fetchData = useCallback(
    async (endpoint, params = {}) => {
      try {
        const data = await getData(endpoint, params);
        return data;
      } catch (error) {
        handleErrorNotification(error);
        return endpoint === "practices" ? null : [];
      }
    },
    [handleErrorNotification]
  );

  const getUserGroups = useCallback(async () => {
    try {
      const session = await Auth.currentSession();
      return session.getIdToken().payload["cognito:groups"];
    } catch (error) {
      handleErrorNotification(error);
      return null;
    }
  }, [handleErrorNotification]);

  const getSecurityGroupMenus = useCallback(
    () => fetchData("security_group_menus", { status: "Active" }),
    [fetchData]
  );

  const getMenus = useCallback(
    () => fetchData("menus", { status: "Active" }),
    [fetchData]
  );

  const getMenuItems = useCallback(
    () => fetchData("menu_items", { status: "Active" }),
    [fetchData]
  );

  const getPractice = useCallback(
    () => fetchData("practices", {}),
    [fetchData]
  );

  const updateMenuItems = useCallback(async () => {
    try {
      if (!securityMapRef.current?.size) return;

      const menuItems = await getMenuItems();
      const securityMapValues = Array.from(securityMapRef.current.values());
      const filteredMenuItems = menuItems.filter((menuItem) =>
        securityMapValues.includes(menuItem.menu)
      );

      setMenuItems(sortMenuItems(filteredMenuItems));
    } catch (error) {
      handleErrorNotification(error);
    }
  }, [getMenuItems, handleErrorNotification]);

  const updateMenus = useCallback(async () => {
    try {
      const [securityGroupMenusData, menusData] = await Promise.all([
        getSecurityGroupMenus(),
        getMenus(),
      ]);

      const filteredSecurityMenus = securityGroupMenusData.filter((menu) =>
        securities.current.includes(menu.security_group)
      );

      const securityMap = new Map(
        filteredSecurityMenus
          .filter((security) => security.read_permission)
          .map((security) => [security.menu_id, security.menu])
      );

      securityMapRef.current = securityMap;
      setSecurityGroupMenus(filteredSecurityMenus);

      const securityMapValues = Array.from(securityMap.values());
      const filteredMenus = menusData
        .filter((menu) => securityMapValues.includes(menu.name))
        .sort((a, b) => (a.menu_order < b.menu_order ? -1 : 1));

      setMenus(filteredMenus);
      await updateMenuItems();
    } catch (error) {
      handleErrorNotification(error);
    }
  }, [
    getSecurityGroupMenus,
    updateMenuItems,
    getMenus,
    handleErrorNotification,
  ]);

  const updateUserData = useCallback(
    async (currentUser) => {
      try {
        const [_securities, practice] = await Promise.all([
          getUserGroups(),
          getPractice(),
        ]);

        if (!practice?.[0]) {
          handleErrorNotification(new Error("Practice data is missing"));
          return;
        }

        securities.current = _securities;

        const userDataValues = {
          practice_name: practice[0].name,
          practiceId: currentUser.attributes["custom:practice_id"],
          offices: currentUser.attributes["custom:offices"],
          email: currentUser.attributes.email,
          name: currentUser.attributes.name,
          firstName: currentUser.attributes.given_name,
          lastName: currentUser.attributes.family_name,
          securities,
        };

        if (JSON.stringify(userDataValues) !== JSON.stringify(user)) {
          setUser(userDataValues);
          setDefaultUserData(userDataValues);
        }

        await updateMenus();
      } catch (error) {
        handleErrorNotification(error);
      }
    },
    [getPractice, getUserGroups, handleErrorNotification, updateMenus, user]
  );

  const setUserData = useCallback(async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await updateUserData(currentUser);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [updateUserData]);

  const userInfo = useMemo(
    () => ({
      ...defaultUserData,
      ...user,
      loading,
      menuItems,
      menus,
      securityGroupMenus,
      updateMenus,
      setUserData,
      updateMenuItems,
      setLoading,
    }),
    [
      defaultUserData,
      menuItems,
      menus,
      securityGroupMenus,
      loading,
      user,
      setUserData,
      updateMenus,
      updateMenuItems,
      setLoading,
    ]
  );

  useEffect(() => {
    setUserData();
  }, [setUserData]);

  return (
    <UserContext.Provider value={userInfo}>{children}</UserContext.Provider>
  );
}

export { UserProvider };
export default UserContext;
