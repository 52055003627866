import { Auth } from "aws-amplify";

export async function getToken() {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    //logger.debug(`Get Token: ${token}`);

    return token;
  } catch (error) {
    try {
      //logger.warn(`Get NEW token`);

      // Get a new token by refreshing the session
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      const newSession = await Auth.currentSession();
      const newToken = newSession.getIdToken().getJwtToken();
      //logger.debug(`New Token acquired ${newToken}`);
      return newToken;
    } catch (refreshError) {
      // If refreshing the token fails, throw the original error
      //logger.error(`Error getting token, could not acquire a token`);
      throw error;
    }
  }
}
