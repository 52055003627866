import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect } from "react";
import { useNotificationHandling } from "../utils/notificationHandle";
import { postErrorData } from "../utils/apis";

export default function ErrorFallback({ error, resetErrorBoundary }) {
  const { handleErrorNotification } = useNotificationHandling();

  useEffect(() => {
    console.log("Error object ErrorFallback:", error); // Log the error object for debugging
    if (error) {
      handleOnError(error, handleErrorNotification);
    } else {
      console.error("Error is undefined or null");
    }
  }, [error, handleErrorNotification]);

  return (
    <Stack
      sx={{
        width: "100vw",
        height: "100vh",
        background: "#020817",
        color: "white",
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="h2"
        fontWeight={500}
        sx={{ width: "100%", maxWidth: "600px", textAlign: "center" }}
      >
        Something went wrong.
      </Typography>
      <Typography
        variant="h3"
        fontWeight={500}
        sx={{
          width: "100%",
          maxWidth: "600px",
          textAlign: "center",
          lineHeight: 1.2,
          mt: 1,
        }}
      >
        Please refresh the page and try again. If the issue persists, contact
        support for assistance.
      </Typography>
    </Stack>
  );
}

const handleOnError = async (error, handleErrorNotification) => {
  if (!error) {
    console.error("Error is undefined or null");
    return;
  }

  console.error("An error occurred:", error);

  const name = error?.name || "";
  const message = error?.message || "";
  const code = error?.code || "";
  const stack = error?.stack || "";

  try {
    await postErrorData({
      name: name,
      message: message,
      code: code,
      description: stack,
      source: "React",
    });
  } catch (error) {
    console.error(error);
  }
};
