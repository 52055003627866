import { useCallback } from "react";
import { toast } from "react-toastify";
import { postErrorData } from "./apis";
import { isProdMode } from "./configs";

export function useNotificationHandling() {
  const handleAxiosError = useCallback((error) => {
    const name = error?.code || "";
    const code = error?.request?.status || "";
    const message = error?.message || "Network error occurred.";
    const description = error?.stack || "";

    postErrorData({
      source: "Axios",
      name,
      message,
      code,
      description,
    }).catch((error) => console.error("Error logging error data:", error));

    return {
      title: "Network Error",
      message: isProdMode
        ? "Please check your internet connection and try again. If the issue persists, contact support."
        : `${code}: ${name}: ${message}`,
      description: isProdMode ? "" : description,
    };
  }, []);

  const handleDatabaseError = useCallback((error) => {
    const name =
      error?.response?.data?.name || error?.name || "Unknown Database Error";
    const code = error?.response?.data?.code || error?.code || "";
    const message =
      error?.response?.data?.message ||
      error?.message ||
      "Unknown database error occurred.";
    const description = error?.response?.data?.description || "";

    postErrorData({
      source: "Database",
      name,
      message,
      code,
      description,
    }).catch((error) => console.error("Error logging error data:", error));

    return {
      title: "Database Error",
      message: isProdMode
        ? "We're unable to process your request right now. Please try again later. If the issue persists, contact support."
        : `${name}: ${message}`,
      description: isProdMode ? "" : description,
    };
  }, []);

  const handleGeneralError = useCallback(
    (error) => ({
      title: error?.name || "Error",
      message: error?.message || "An unknown error occurred",
      description: error?.description || "",
    }),
    []
  );

  const handleErrorByType = useCallback(
    (error, source) => {
      if (source === "Axios") return handleAxiosError(error);
      if (source === "Database") return handleDatabaseError(error);
      return handleGeneralError(error);
    },
    [handleGeneralError, handleDatabaseError, handleAxiosError]
  );

  const createToast = useCallback(
    ({ title, message, description, type = "blank" }) => {
      const toastContent = (
        <div>
          <h6 className="Toastify__toast-title">{title}</h6>
          <p className="Toastify__toast-message">{message}</p>
          {description && (
            <p className="Toastify__toast-description">{description}</p>
          )}
        </div>
      );

      switch (type) {
        case "success":
          toast.success(toastContent, { autoClose: 3000 });
          break;
        case "warning":
          toast.warn(toastContent, { autoClose: 5000 });
          break;
        case "error":
          toast.error(toastContent, { autoClose: false });
          break;
        default:
          toast(toastContent);
      }
    },
    []
  );

  const handleSuccessNotification = useCallback(
    (title, message, description) => {
      createToast({ title, message, description, type: "success" });
    },
    [createToast]
  );

  const handleWarningNotification = useCallback(
    (title, message, description) => {
      createToast({ title, message, description, type: "warning" });
    },
    [createToast]
  );

  const handleInfoNotification = useCallback(
    (title, message, description) => {
      createToast({ title, message, description });
    },
    [createToast]
  );

  const handleErrorNotification = useCallback(
    (error) => {
      let source = "Unknown";
      if (error.code === "ERR_BAD_REQUEST" && error.response?.data?.name)
        source = "Database";
      else if (error.name === "AxiosError") source = "Axios";

      const { title, message, description } = handleErrorByType(error, source);
      createToast({ title, message, description, type: "error" });
    },
    [createToast, handleErrorByType]
  );

  return {
    handleErrorNotification,
    handleSuccessNotification,
    handleInfoNotification,
    handleWarningNotification,
  };
}
