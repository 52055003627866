import React, { useState, useEffect, useContext, Suspense } from "react";
import { CircularProgress, CssBaseline } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Authenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { Box } from "@mui/system";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/ErrorFallback";
import UserContext from "./contexts/UserContext";
import { ThemeProvider } from "@mui/material/styles";
import SessionChecker from "./components/sessionChecker/SessionChecker";
import { initializeFontAwesome } from "./utils/fontAwesome";

// Lazy load components with explicit naming for better debugging
const AppLayout = React.lazy(() =>
  import("./components/appLayout/AppLayout").then((module) => ({
    default: module.default,
  }))
);
const Login = React.lazy(() =>
  import("./pages/Login").then((module) => ({
    default: module.default,
  }))
);
const AppRoutes = React.lazy(() =>
  import("./AppRoutes").then((module) => ({
    default: module.default,
  }))
);

// Initialize FontAwesome
initializeFontAwesome();

// Memoize LoadingSpinner component since it's static
const LoadingSpinner = React.memo(() => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100vh"
    width="100%"
  >
    <CircularProgress />
  </Box>
));

function App() {
  const [theme, colorMode] = useMode();
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    loading: true,
  });
  const { loading: userLoading } = useContext(UserContext);

  useEffect(() => {
    let mounted = true;

    const checkAuth = async () => {
      try {
        await Auth.currentSession();
        if (mounted) {
          setAuthState({ isAuthenticated: true, loading: false });
        }
      } catch (error) {
        if (mounted) {
          setAuthState({ isAuthenticated: false, loading: false });
        }
      }
    };

    checkAuth();

    return () => {
      mounted = false;
    };
  }, []);

  // Memoize the login handler
  const handleSuccessfulLogin = React.useCallback(() => {
    setAuthState((prev) => ({ ...prev, isAuthenticated: true }));
  }, []);

  if (authState.loading || userLoading) {
    return <LoadingSpinner />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <Authenticator.Provider>
            <CssBaseline />
            {authState.isAuthenticated && <SessionChecker />}
            <Suspense fallback={<LoadingSpinner />}>
              <div className="app">
                {authState.isAuthenticated ? (
                  <AppLayout>
                    <AppRoutes
                      isAuthenticated={authState.isAuthenticated}
                      handleSuccessfulLogin={handleSuccessfulLogin}
                    />
                  </AppLayout>
                ) : (
                  <Box className="login-section">
                    <Login onSuccessfulLogin={handleSuccessfulLogin} />
                  </Box>
                )}
              </div>
            </Suspense>
          </Authenticator.Provider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
